table.data tbody tr, .list-item {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7F8F9', endColorstr='#EAEBEC');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7F8F9', endColorstr='#EAEBEC')";
}

/* Commented this out since it's not needed anymore - version 1 ticket D-04120 */
/*form input[type=text], form input[type=date], form input[type=password], form textarea
{
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#ededed');
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#ededed')";
}*/

/* This causes issues with the magnifying glass icon in search fields. Commenting out for now. DEV-10761 */
/*form input[type=text]:focus, form textarea:focus
{
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0ffe9', endColorstr='#cee9c2');
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0ffe9', endColorstr='#cee9c2')";
}*/

form {
	input {
		&[type=date]:focus, &[type=password]:focus {
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0ffe9', endColorstr='#cee9c2');
			-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0ffe9', endColorstr='#cee9c2')";
		}
	}

	.field_with_errors input {
		&[type=text], &[type=date], &[type=password] {
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8');
			-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8')";
		}
	}

	input.error {
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8');
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8')";
	}

	.field_with_errors input {
		&[type=text]:focus, &[type=date]:focus, &[type=password]:focus {
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8');
			-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8')";
		}
	}

	input.error:focus {
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8');
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5ebea', endColorstr='#e4cbc8')";
	}
}

/* calendars -- fill in as necessary */

#calendars div.calendar {
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f8f9', endColorstr='#eaebec');
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f8f9', endColorstr='#eaebec')";
	border: 1px solid #D0D0D0;
	width: 247px;
}

table.disabled button {
	background-color: #f8f8f8 !important;
	border: 1px solid #D0D0D0 !important;

	&:hover {
		background-color: #f8f8f8 !important;
		border: 1px solid #D0D0D0 !important;
	}
}

input.disabled, table.x-item-disabled button {
	background-color: #f8f8f8 !important;
	border: 1px solid #D0D0D0 !important;

	&:hover {
		background-color: #f8f8f8 !important;
		border: 1px solid #D0D0D0 !important;
	}
}